'use client'

import { cn } from '@/shared/utils/cn'

type IconButtonProps = {
	onClick: () => void
	edge?: 'start' | 'end'
	ariaLabel: string
	children: React.ReactNode
	className?: string
}

export const IconButton = ({ onClick, edge, ariaLabel, children, className }: IconButtonProps) => {
	return (
		<button
			onClick={onClick}
			className={cn(
				'text-white',
				edge === 'start' && 'mr-2',
				edge === 'end' && 'ml-2',
				className
			)}
			aria-label={ariaLabel}
		>
			{children}
		</button>
	)
}