'use client'
import { useState, useEffect, ImgHTMLAttributes } from 'react'

type ImgProps = {
	src: string
	alt: string
	className?: string
	width?: number | string
	height?: number | string
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'alt' | 'width' | 'height'>

export const Img = ({
	src,
	alt,
	className,
	width,
	height,
	...imgProps
}: ImgProps) => {
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		const img = new Image()
		img.src = src
		img.onload = () => setLoaded(true)
	}, [src])

	const containerStyle = {
		width: width,
		height: height,
		position: 'relative' as const,
	}

	const imgStyle = {
		width: '100%',
		height: '100%',
		objectFit: 'cover' as const,
	}

	const sizes = `(max-width: ${width}px) 100vw, ${width}px`

	return (
		<div style={containerStyle} className={className}>
			{!loaded && (
				<div
					className="absolute inset-0 bg-gray-700 animate-pulse"
					style={{ borderRadius: 'inherit' }}
				/>
			)}
			{loaded && (
				<img
					src={src}
					alt={alt}
					sizes={sizes}
					style={{ ...imgStyle, borderRadius: 'inherit' }}
					className="transition-opacity duration-500 opacity-100"
					{...imgProps}
				/>
			)}
		</div>
	)
}
