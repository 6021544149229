'use client'
import React, { useState, useRef, useEffect } from 'react'
import { FiMenu, FiX } from 'react-icons/fi'
import { usePathname } from 'next/navigation'
import { cn } from '@/shared/utils/cn'
import { IconButton } from './IconButton'

type DrawerProps = {
	open: boolean
	onClose: () => void
	children: React.ReactNode
	className?: string
}

const Drawer = ({ open, onClose, className, children }: DrawerProps) => {
	const drawerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const handleEscape = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				onClose()
			}
		}

		const handleClick = (event: MouseEvent) => {
			if (drawerRef.current && drawerRef.current.contains(event.target as Node)) {
				const target = event.target as HTMLElement
				if (target.tagName === 'A') {
					onClose()
				}
			}
		}

		if (open) {
			document.addEventListener('keydown', handleEscape)
			document.addEventListener('click', handleClick)
			drawerRef.current?.focus()
		}

		return () => {
			document.removeEventListener('keydown', handleEscape)
			document.removeEventListener('click', handleClick)
		}
	}, [open, onClose])

	if (!open) return null

	return (
		<div
			className={cn('fixed inset-0 bg-black bg-opacity-50 z-50', className)}
			onClick={onClose}
			role="dialog"
			aria-modal="true"
			aria-labelledby="drawer-title"
		>
			<div
				ref={drawerRef}
				className="fixed inset-0 bg-black shadow-lg transform transition-transform duration-300 ease-in-out flex flex-col"
				style={{ transform: open ? 'translateX(0)' : 'translateX(-100%)' }}
				onClick={(e) => e.stopPropagation()}
				tabIndex={-1}
			>
				<div className="flex justify-between p-4">
					<h2 id="drawer-title" className="text-white text-xl">
						Menu
					</h2>
					<IconButton onClick={onClose} edge="end" ariaLabel="close menu">
						<FiX size={24} />
					</IconButton>
				</div>
				<div className="flex-grow overflow-y-auto">{children}</div>
			</div>
		</div>
	)
}

type ResponsiveHeaderProps = {
	logoBlock: React.ReactNode
	drawerContent?: React.ReactNode
}

export const ResponsiveHeader = ({
	logoBlock,
	drawerContent,
}: ResponsiveHeaderProps) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const pathname = usePathname()

	const toggleDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen)
	}

	const closeDrawer = () => {
		setIsDrawerOpen(false)
	}

	useEffect(() => {
		setIsDrawerOpen(false)
	}, [pathname])

	const wrappedDrawerContent = drawerContent ? (
		<div onClick={closeDrawer}>
			{drawerContent}
		</div>
	) : null

	return (
		<div className="flex justify-between items-center">
			<div className="flex items-center justify-between gap-6 w-full">
				{logoBlock}
				{drawerContent && (
					<div className="lg:hidden ml-2 bg-gray-800 rounded-full flex items-center justify-center size-12">
						<IconButton onClick={toggleDrawer} ariaLabel="open menu">
							<FiMenu size={24} className="mx-auto" />
						</IconButton>
					</div>
				)}
			</div>
			{drawerContent && (
				<nav className="hidden lg:flex items-center flex-grow pl-6 w-full" aria-label="Main navigation">
					{drawerContent}
				</nav>
			)}
			{drawerContent && (
				<Drawer open={isDrawerOpen} onClose={toggleDrawer} className="flex lg:hidden">
					<nav aria-label="Mobile navigation">
						<div className="p-4">{wrappedDrawerContent}</div>
					</nav>
				</Drawer>
			)}
		</div>
	)
}
