import { cn } from '@/shared/utils/cn'
import { ReactNode } from 'react'

type TSectionWrapperProps = {
	id?: string
	children: ReactNode
	title?: string | ReactNode
	sectionTag?: string
	subtitle?: string | ReactNode
	headingBlock?: ReactNode
	type?: 'bordered'
	className?: string
}

type TSectionContentProps = {
	children: ReactNode
	title?: string | ReactNode
	sectionTag?: string
	subtitle?: string | ReactNode
	headingBlock?: ReactNode
}

const SectionContent = ({
	children,
	title,
	sectionTag,
	subtitle,
	headingBlock,
}: TSectionContentProps) => {
	return (
		<>
			{headingBlock
				? headingBlock
				: title && (
						<div className="mx-auto max-w-2xl text-center mb-16">
							{sectionTag && <div className="text-sm mb-8">{sectionTag}</div>}
							<h2 className="text-4xl font-bold text-white sm:text-5xl tracking-tight">
								{title}
							</h2>
							{subtitle && (
								<div className="mt-6 text-lg text-gray-400">{subtitle}</div>
							)}
						</div>
				  )}
			{children}
		</>
	)
}

export const SectionWrapper = ({
	id,
	children,
	title,
	sectionTag,
	subtitle,
	headingBlock,
	type,
	className,
}: TSectionWrapperProps) => {
	return (
		<div
			className={cn(
				'py-12 sm:py-20 relative isolate w-full',
				type === 'bordered' ? 'px-6 lg:px-8' : className
			)}
			id={id}
		>
			{type === 'bordered' && (
				<div className={cn("border-2 border-white rounded-3xl py-8 sm:py-16 max-w-7xl mx-auto", className)}>
					<div className="max-w-6xl mx-auto px-6 lg:px-8">
						<SectionContent
							title={title}
							sectionTag={sectionTag}
							subtitle={subtitle}
							headingBlock={headingBlock}
						>
							{children}
						</SectionContent>
					</div>
				</div>
			)}
			{type !== 'bordered' && (
				<div className="mx-auto max-w-6xl px-6 lg:px-8">
					<SectionContent
						title={title}
						sectionTag={sectionTag}
						subtitle={subtitle}
						headingBlock={headingBlock}
					>
						{children}
					</SectionContent>
				</div>
			)}
		</div>
	)
}
